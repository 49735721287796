import React, { useState, FocusEvent, useEffect, useRef } from 'react'
import Select, { components } from 'react-select'
import classNames from 'classnames'

import { EloTooltip } from '@elo-ui/components/elo-tooltip'
import { EloInfoIcon } from '@elo-ui/components/icons/regular'
import { TooltipProps } from '@elo-ui/types'

import {
  ClearIndicator,
  DropdownIndicator,
  MultiValueRemove,
  MultiValueContainer,
  DefaultOptionComponent,
  OptionWithActionsComponent,
  DefaultSingleValue,
  DefaultMultiValueLabel,
  ImageOptionComponent,
  ImageSingleValue,
  ImageMultiValueLabel,
  ImageTextOptionComponent,
  ImageTextSingleValue,
  ImageTextMultiValueLabel,
  ImageTextSubtitleOptionComponent,
  ImageTextSubtitleSingleValue,
  ImageTextSubtitleMultiValueLabel,
  ImageTextSubtitleMultiValue,
  OptionActions,
} from './components'

import './elo-select.scss'

type Size = 'large' | 'extra-large'

interface Props<OptionType> {
  options: OptionType[] | unknown
  value: OptionType | OptionType[] | unknown
  onChange: (value: OptionType) => OptionType | void
  isOptionDisabled?: (value: OptionType) => boolean
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void
  isMulti?: boolean
  isClearable?: boolean
  isSearchable?: boolean
  className?: string
  label?: React.ReactChild
  id?: string
  error?: boolean
  errorText?: string
  hintText?: string
  required?: boolean
  success?: boolean
  successText?: string
  disabled?: boolean
  placeholder?: string
  SingleValue?: typeof Select.components.DefaultSingleValue
  MultiValueLabel?: typeof Select.components.DefaultMultiValueLabel
  OptionComponent?: typeof Select.components.DefaultOptionComponent
  MultiValue?: typeof Select.components.MultiValue
  size?: Size
  optionPlaceholder?: string
  warningMessage?: string
  tooltip?: string
  tooltipIcon?: React.ReactElement
  tooltipOptions?: TooltipProps
  isTouched?: boolean
  onBlur?: (e: FocusEvent<HTMLInputElement>, value: OptionType | OptionType[] | unknown) => void
  formatOptionLabel?: (options: { label: string; value: string }[]) => void
  styles?: Record<string, unknown>
  useMenuPortal?: boolean
}

function SelectComponent<Option>({
  value = undefined,
  className,
  options = [],
  onChange,
  label,
  id = 'SelectComponent',
  error,
  errorText,
  hintText,
  required,
  success,
  successText,
  disabled,
  isMulti = false,
  isClearable = false,
  isSearchable = false,
  placeholder = '',
  SingleValue = DefaultSingleValue,
  MultiValueLabel = DefaultMultiValueLabel,
  OptionComponent = DefaultOptionComponent,
  MultiValue = components.MultiValue,
  size = 'extra-large',
  optionPlaceholder = '',
  warningMessage,
  tooltipOptions,
  isTouched = false,
  onBlur,
  formatOptionLabel,
  isOptionDisabled,
  styles,
  useMenuPortal = false,
  ...restProps
}: Props<Option>) {
  const [touched, setTouched] = useState(isTouched)
  const ref = useRef(null)
  useEffect(() => {
    setTouched(isTouched)
  }, [isTouched])

  const inputClasses = classNames(className, 'elo-input', 'elo-select', `elo-select--${size}`, {
    'elo-input--error': touched && error,
    'elo-input--success': success,
    'elo-select--searchable': isSearchable,
    disabled,
  })

  return (
    <div className={inputClasses} ref={ref}>
      <label className='elo-input__label' htmlFor={id}>
        {label && (
          <span className='elo-input__label-text'>
            {label}
            {required && <span className='elo-input__label-required'>*</span>}
            {tooltipOptions && (
              <span className='elo-input__label-tooltip'>
                <EloTooltip {...tooltipOptions}>
                  {tooltipOptions.children ? tooltipOptions.children : <EloInfoIcon size={16} />}
                </EloTooltip>
              </span>
            )}
          </span>
        )}
        <Select
          hideSelectedOptions={false}
          inputId={id}
          instanceId={`elo-react-select-${id}`}
          className='elo-react-select'
          classNamePrefix='elo-react-select'
          options={options}
          value={value}
          onChange={(option: Option) => {
            setTouched(true)
            onChange(option)
          }}
          onBlur={(e: FocusEvent<HTMLInputElement>) => {
            setTouched(true)
            onBlur && onBlur(e, value)
          }}
          label={label}
          warningMessage={warningMessage}
          hasError={touched && error}
          captureMenuScroll={true}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator,
            ClearIndicator,
            MultiValueRemove,
            MultiValueContainer,
            Option: OptionComponent,
            SingleValue: SingleValue,
            MultiValueLabel: MultiValueLabel,
            MultiValue: MultiValue,
            NoOptionsMessage: () => <div className='option-placeholder'>{optionPlaceholder}</div>,
          }}
          isClearable={isClearable}
          isSearchable={isSearchable}
          isMulti={isMulti}
          isDisabled={disabled}
          placeholder={placeholder}
          closeMenuOnSelect={!isMulti}
          formatOptionLabel={formatOptionLabel}
          isOptionDisabled={isOptionDisabled}
          styles={
            useMenuPortal
              ? {
                  ...styles,
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }
              : styles
          }
          menuPortalTarget={useMenuPortal ? document.body : null}
          menuShouldScrollIntoView
          {...restProps}
        />
      </label>

      {success && successText && <span className='elo-input__success'>{successText}</span>}
      {touched && error && errorText && <span className='elo-input__error'>{errorText}</span>}
      {hintText && <span className='elo-input__hint'>{hintText}</span>}
    </div>
  )
}

const EloSelect = {
  Select: SelectComponent,

  ImageOptionComponent,
  ImageSingleValue,
  ImageMultiValueLabel,

  ImageTextOptionComponent,
  ImageTextSingleValue,
  ImageTextMultiValueLabel,

  ImageTextSubtitleOptionComponent,
  ImageTextSubtitleSingleValue,
  ImageTextSubtitleMultiValueLabel,
  ImageTextSubtitleMultiValue,
  OptionWithActionsComponent,
  OptionActions,
}

export { EloSelect }
