import { COLORS } from '@elo-kit/constants/general.constants'
import { getFontStyles } from '@elo-kit/utils/fonts.utils'
import { cleanupFonts } from 'utils/fonts.utils'

const { eloBlack, eloBlue, eloWhite } = COLORS

// TODO: update with using createUseStyles
const productStyles = (theme) => ({
  productBlockContainer: {
    backgroundColor: theme.background || eloWhite,
    width: '100%',
    minHeight: 200,
  },

  productName: {
    fontFamily: cleanupFonts(theme.textFont) || 'Montserrat Medium',
    fontSize: `${theme.textSize || 40}px`,
    ...getFontStyles(theme.textStyle || 'bold'),
    color: theme.textColor || eloBlack,
    marginBottom: 15,
    overflowWrap: 'normal',
    overflowX: 'auto',
    lineHeight: 2,
  },

  oldPrice: {
    display: 'flex',

    '& div:first-child': {
      position: 'relative',
      padding: '0 3px',
      lineHeight: 0,
      marginBottom: 5,

      '& span': {
        lineHeight: 1,
        fontFamily: cleanupFonts(theme.oldPriceFont) || 'Montserrat Medium',
        fontSize: `${theme.oldPriceSize || 18}px`,
        ...getFontStyles(theme.oldPriceStyle || 'bold'),
        color: theme.oldPriceColor || '#9e9e9e',
      },
    },
  },

  oldPriceLine: {
    position: 'absolute',
    backgroundColor: '#ff4747',
    margin: 0,
    top: 'calc(50% - 2px)',
    right: 0,
    left: 0,
  },

  productContainer: {
    padding: '55px 0',

    '& .buy-button': {
      width: 'fit-content',
      minHeight: 50,
      borderRadius: `${theme.radius === undefined ? 4 : theme.radius}px`,
      backgroundColor: [theme.buttonColor || eloBlue, '!important'],
      borderColor: [theme.buttonColor || eloBlue, '!important'],
      color: [theme.buttonTextColor || eloWhite, '!important'],
      boxShadow: 'none !important',
      fontFamily: cleanupFonts(theme.buttonFont) || 'Montserrat Medium',
      fontSize: `${theme.buttonSize || 15}px`,
      textTransform: 'uppercase',
      display: 'flex',
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'center',
      ...getFontStyles(theme.buttonStyle),
      wordBreak: 'break-word',
      padding: '10px 20px',

      '&:hover': {
        opacity: 0.8,
      },

      '&:active': {
        opacity: 0.6,
      },
    },

    '& .back': {
      fontFamily: cleanupFonts(theme.backButtonFont) || 'Montserrat Medium',
      fontSize: `${theme.backButtonSize || 15}px`,
      color: theme.backButtonColor || eloBlue,
      textDecoration: 'none',
      ...getFontStyles(theme.backButtonStyle),
      marginBottom: 10,

      '&:hover': {
        opacity: 0.8,
      },
    },

    '& .horizontal-separator': {
      borderBottom: '1px solid #eaeaea',
      margin: '37px 0',
    },

    '& .lessons-list .title': {
      fontFamily: cleanupFonts(theme.planTitleFont) || 'Montserrat Medium',
      fontSize: `${theme.planTitleSize || 24}px`,
      ...getFontStyles(theme.planTitleStyle),
      color: theme.planTitleColor || eloBlack,
    },

    '@media (max-width: 576px)': {
      padding: '15px',
    },
  },

  productTopContent: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 30,

    '& .no-cover': {
      height: 429,
      backgroundColor: '#efedef',

      '& > .far.fa-image': {
        fontSize: 100,
      },
    },

    '& .current-price': {
      width: 254,
      fontFamily: cleanupFonts(theme.priceFont) || 'Montserrat Medium',
      fontSize: `${theme.priceSize || 70}px`,
      ...getFontStyles(theme.priceStyle || 'bold'),
      lineHeight: 0.86,
      color: theme.priceColor || eloBlack,
      marginBottom: 10,
    },

    '& .currency': {
      width: 29,
      height: 46,
      fontFamily: cleanupFonts(theme.currencyFont) || 'Montserrat Reg',
      fontSize: `${theme.currencySize || 40}px`,
      ...getFontStyles(theme.currencyStyle || 'bold'),
      color: theme.currencyColor || '#808080',
    },

    '& .pay-info': {
      width: 71,
      height: 12,
      lineHeight: 0.86,
      fontFamily: cleanupFonts(theme.payInfoFont) || 'Montserrat Reg',
      fontSize: `${theme.payInfoSize || 14}px`,
      ...getFontStyles(theme.payInfoStyle),
      color: theme.payInfoColor || '#9e9e9e',
      marginBottom: 41,
    },

    '@media (max-width: 991px)': {
      margin: '0 0 30px',

      '& .embed-responsive-item': {
        height: '100% !important',
      },
    },
  },

  productBottomContent: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: 0,

    '& .custom-shop-product-lessons-tree': {
      fontFamily: cleanupFonts(theme.planFont) || 'Montserrat Medium',
      fontSize: `${theme.planSize || 14}px`,
      ...getFontStyles(theme.planStyle),

      '& .row': {
        height: 48,
        borderRadius: 4,
        backgroundColor: '#f0f0f0',
      },

      '& .list-group': {
        transition: 'max-height 0.15s ease-out',

        '&.hidden': {
          maxHeight: 0,
          overflow: 'hidden',
          transition: 'max-height 0.25s ease-in',
        },
      },

      '& .list-group-item': {
        border: 'none',
        borderRadius: 0,
        backgroundColor: '#f0f0f0',
        borderBottom: '2px solid #ffffff',
        zIndex: 1,
        display: 'flex',
        color: theme.planColor || eloBlack,
        flexWrap: 'wrap',
        '& a': {
          cursor: 'pointer',
          color: theme.planColor || eloBlack,
          '&.has-preview:nth-child(1)': {
            marginRight: '30px',
          },
          '&:hover': {
            textDecoration: 'none',
            opacity: 0.7,
          },
        },
        '& .lesson-name:hover': {
          cursor: 'pointer',
          opacity: 0.7,
        },
      },

      '& .children': {
        '& .list-group-item': {
          paddingLeft: 40,
          backgroundColor: '#f8f8f8',
          borderBottom: '2px solid #eaeaea',
        },

        '& .children .list-group-item': {
          paddingLeft: 60,
          borderBottom: '1px solid #eaeaea',
        },
      },

      '& .fa': {
        cursor: 'pointer',

        '&.fa-angle-right': {
          color: theme.buttonColor || eloBlue,
        },

        '&.fa-angle-down': {
          color: '#9e9e9e',
        },
      },

      '& .preview-button': {
        width: 'auto',
        height: 'auto',
        padding: '5px 15px',
        textTransform: 'capitalize',
        borderRadius: 4,
        backgroundColor: [theme.previewButtonColor || eloBlue, '!important'],
        borderColor: [theme.previewButtonColor || eloBlue, '!important'],
        color: [theme.previewButtonTextColor || eloWhite, '!important'],
        boxShadow: 'none !important',
        fontFamily: cleanupFonts(theme.previewButtonFont) || 'Montserrat Reg',
        fontSize: `${theme.previewButtonSize || 13}px`,
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        ...getFontStyles(theme.previewButtonStyle),
        wordBreak: 'break-word',

        '&:hover': {
          opacity: 0.8,
        },

        '&:active': {
          opacity: 0.6,
        },
      },
    },
  },

  coverContainer: {
    display: 'flex',
    '& .covers': {
      height: '100%',
      '& img': {
        maxWidth: 500,
        maxHeight: 500,
      },
    },
    '@media (max-width: 991px)': {
      width: '100%',
      justifyContent: 'center',
      '& .covers img': {
        width: '100%',
      },
    },
  },

  slideCoverContainer: {
    display: 'flex',
    width: 500,
    '& .covers': {
      height: '100%',
      width: '100%',
    },
    '@media (max-width: 991px)': {
      width: '100%',
    },
  },

  priceContainer: {
    display: 'flex',
    flexGrow: 1,
    margin: '20px 0 20px 50px',
    '@media (max-width: 991px)': {
      width: '100%',
      margin: '50px 0 0 0',
      justifyContent: 'center',
    },
  },

  priceContent: {
    alignContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },

  mainContentContainer: {
    width: '60%',
    '@media (max-width: 576px)': {
      width: '100%',
    },

    '& .description': {
      wordBreak: 'break-word',
    },

    '& .terms': {
      fontSize: 14,
      fontFamily: cleanupFonts(theme.planFont) || 'Montserrat Reg',
      color: eloBlack,
    },

    '& .download-list-title': {
      fontFamily: cleanupFonts(theme.downloadTitleFont) || 'Montserrat Medium',
      fontSize: `${theme.downloadTitleSize || 24}px`,
      ...getFontStyles(theme.downloadTitleStyle),
      color: theme.downloadTitleColor || eloBlack,
      marginBottom: 20,
    },

    '& .tickets-title': {
      fontFamily: cleanupFonts(theme.ticketsTitleFont) || 'Montserrat Medium',
      fontSize: `${theme.ticketsTitleSize || 24}px`,
      ...getFontStyles(theme.ticketsTitleStyle),
      color: theme.ticketsTitleColor || eloBlack,
      marginBottom: 20,
    },

    '& .tickets-details-item': {
      fontFamily: cleanupFonts(theme.ticketsDetailsFont) || 'Montserrat Medium',
      fontSize: `${theme.ticketsDetailsSize || 14}px`,
      ...getFontStyles(theme.ticketsDetailsStyle),
      color: theme.ticketsDetailsColor || eloBlack,
      borderBottom: '1px solid #eaeaea',
      marginBottom: 10,
      paddingBottom: 10,
      '& .fields-container__vertical-separator': {
        color: '#eaeaea',
        fontStyle: 'normal',
        margin: '0 30px 0px',
      },
      '& .ticket-date': {
        wordBreak: 'break-word',
      },
      '& .ticket-location': {
        wordBreak: 'break-word',
      },
    },

    '& .download-title': {
      fontFamily: cleanupFonts(theme.downloadTitleFont) || 'Montserrat Medium',
      fontSize: `${theme.downloadTitleSize || 24}px`,
      ...getFontStyles(theme.downloadTitleStyle),
      color: theme.downloadTitleColor || eloBlack,
      marginBottom: 20,
    },

    '& .download-details': {
      fontFamily: cleanupFonts(theme.downloadDetailsFont) || 'Montserrat Reg',
      fontSize: `${theme.downloadDetailsSize || 14}px`,
      ...getFontStyles(theme.downloadDetailsStyle),
      textDecoration: `${getFontStyles(theme.downloadDetailsStyle).textDecoration} !important`,
      color: `${theme.downloadDetailsColor || eloBlack} !important`,

      '& .audio-button': {
        color: '#b7b7b7 !important',
      },

      '& .icon': {
        textAlign: 'center',
      },

      '& .file-item-preview': {
        width: 37,
        height: 37,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 4,
        backgroundColor: '#efedef',

        '& i': {
          fontSize: '16px !important',
          color: '#b7b7b7',
        },
      },
    },
  },

  arrows: {
    color: [eloWhite, '!important'],

    '&:hover': {
      opacity: 0.8,
    },
  },
})

export default productStyles
